/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import React from "react"
import { CartContextProvider } from "./src/context/cartContext"
import { CustomerContextProvider } from "./src/context/customerContext"
import { HubbleOrderContextProvider } from "./src/context/hubbleOrderContext"

// highlight-start
import { appendThirdPartyScripts, loadPageSpecificScripts } from "./src/utils/externalScripts"
import { dom } from '@fortawesome/fontawesome-svg-core'
import {AbTestsContextProvider} from "./src/context/abTestsContext"

export const onInitialClientRender = () => {
  window.addEventListener(
    'pointermove',
    () => appendThirdPartyScripts('pointer'),
    { once: true }
  )

  window.addEventListener(
    'touchmove',
    () => appendThirdPartyScripts('touch'),
    { once: true }
  )

  window.addEventListener(
    'scroll',
    () => appendThirdPartyScripts('scroll'),
    { once: true }
  )

  if (typeof window.location !== "undefined") {
    loadPageSpecificScripts(window.location.pathname)
  }
}

export const wrapRootElement = ({ element }) => {
  return (
    <CartContextProvider>
      <CustomerContextProvider>
        <HubbleOrderContextProvider>
          <AbTestsContextProvider>
            <style>{dom.css()}</style>
            {element}
          </AbTestsContextProvider>
        </HubbleOrderContextProvider>
      </CustomerContextProvider>
    </CartContextProvider>)
}

export const onRouteUpdate = ({ location, prevLocation }) => {
  if (location.pathname != prevLocation?.pathname) {
    if (typeof document != "undefined") {
      // scroll to the top of the page
      const container = document.querySelector('#scroll-top-position');
      if (container) container.scrollIntoView(true);
    }
  }
}

// gatsby-browser.js
export const shouldUpdateScroll = ({ routerProps, prevRouterProps }) => {
  const { pathname, hash } = routerProps.location

  if (pathname !== prevRouterProps?.location?.pathname) {
    return window.scroll({ top: 0, behavior: 'instant' });
  }

  switch (pathname) {
    case "/glasses":
    case "/sunglasses":
      return !!hash

    default:
      break
  }
}
