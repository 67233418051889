import React, { createContext, useState, useEffect } from "react"

const defaultState = {
  isCartInitialized: false,
  cartItems: [],
  cartItemsQuantity: 0,
  wizardFlow: 1,
  currentFrame: "",
  currentVariant: "",
  currentVariantInfo: null,
  currentCartItem: "",
  prescriptionType: "",
  lensType: "",
  cartDisplay: false,
  hasCurrentValues: false,
  currentTryOnImage: "",
  headerColor: "black",
  order: null,
  currentItem: null,
  subtotal: 0,
  orderSubtotal: 0,
  isOrderError: false,
  discountCode: "",
  isHydro: false,
  isSkyhy: false,
  isAvg: false,
  isLensesInCart: false,
  taxes: 0,
  setCartItems: Array => {},
  setCartDisplay: Boolean => {},
  setCurrentFrame: String => {},
  setCurrentVariant: String => {},
  setCurrentVariantInfo: Object => {},
  setCurrentCartItem: String => {},
  setPrescriptionType: String => {},
  setLensType: String => {},
  setWizardFlow: Integer => {},
  setCurrentTryOnImage: String => {},
  setHeaderColor: String => {},
  addItemCart: Object => {},
  clearCart: () => {},
  removeItemCart: Integer => {},
  setQuantity: Integer => {},
  setOrder: Object => {},
  setCurrentItem: Object => {},
  createOrder: Object => {},
  clearOrder: () => {},
  removeOrder: () => {},
  setIsOrderError: Boolean => {},
  setDiscountCode: () => {},
  setIsHydro: () => {},
  setIsSkyhy: () => {},
  setIsAvg: () => {},
  setIsLensesInCart: () => {},
  setTaxes: Integer => {}
}

export const CartContext = createContext(defaultState)

const CartContextProvider = props => {
  const [isCartInitialized, setIsCartInitialized] = useState(false)
  const [cartItems, setCartItems] = useState([])
  const [cartItemsQuantity, setCartItemsQuantity] = useState(0)
  const [order, setOrder] = useState()
  const [currentItem, setCurrentItem] = useState()
  const [isOrderError, setIsOrderError] = useState(false)
  const [wizardFlow, setWizardFlow] = useState(1)
  const [currentFrame, setCurrentFrame] = useState("")
  const [currentVariant, setCurrentVariant] = useState("")
  const [currentVariantInfo, setCurrentVariantInfo] = useState()
  const [prescriptionType, setPrescriptionType] = useState("")
  const [currentCartItem, setCurrentCartItem] = useState("")
  const [lensType, setLensType] = useState("")
  const [cartDisplay, setCartDisplay] = useState(false)
  const [hasCurrentValues, setHasCurrentValues] = useState(false)
  const [currentTryOnImage, setCurrentTryOnImage] = useState("")
  const [headerColor, setHeaderColor] = useState("black")
  const [subtotal, setSubtotal] = useState(0)
  const [orderSubtotal, setOrderSubtotal] = useState(0)
  const [discountPercentage, setDiscountPercentage] = useState(0)
  const [discountCode, setDiscountCode] = useState("")
  const [isHydro, setIsHydro] = useState()
  const [isSkyhy, setIsSkyhy] = useState()
  const [isAvg, setIsAvg] = useState()
  const [isLensesInCart, setIsLensesInCart] = useState()
  const [taxes, setTaxes] = useState(0)

  useEffect(() => {
    const usersCart = localStorage.getItem("cart")
    const usersCurrentFrame = localStorage.getItem("currentFrame")
    const usersCurrentVariant = localStorage.getItem("currentVariant")
    const usersCurrentCartItem = localStorage.getItem("currentCartItem")
    const usersCurrentVariantInfo = localStorage.getItem("currentVariantInfo")
    const usersSavedTryOnImage = localStorage.getItem("tryOnImage")

    if (usersCart !== null) {
      const parsedCartItems = JSON.parse(usersCart)
      const newCartItems = [
        ...parsedCartItems,
        ...cartItems.filter(
          ({ id }) => !parsedCartItems.some(item => item.id === id)
        ),
      ]
      const newCartItemsQuantity = newCartItems.reduce(
        (total, { quantity }) => {
          return total + +quantity
        }, 0)

      setCartItems(newCartItems)
      setCartItemsQuantity(newCartItemsQuantity)
      setIsCartInitialized(true)
    }
    if (usersCurrentFrame !== null) {
      setCurrentFrame(usersCurrentFrame)
    }
    if (usersCurrentVariant !== null) {
      setCurrentVariant(usersCurrentVariant)
    }
    if (usersCurrentVariantInfo !== null) {
      setCurrentVariantInfo(JSON.parse(usersCurrentVariantInfo))
    }
    if (usersCurrentCartItem !== null) {
      setCurrentCartItem(usersCurrentCartItem)
    }
    if (usersSavedTryOnImage !== null) {
      setCurrentTryOnImage(usersSavedTryOnImage)
    }
  }, [])

  useEffect(() => {
    if (!cartItems) return
    const newSubtotal = cartItems.reduce((result, { price, sale_price, isOneEye, type, quantity }) => {
      if (quantity) {
        return result + quantity * Number(sale_price || price) * ((type === "contacts" && !isOneEye && Number(sale_price || price) !== 1) ? 2 : 1)
      }
      return result + Number(sale_price || price) * ((type === "contacts" && !isOneEye && Number(sale_price || price) !== 1) ? 2 : 1)
    }, 0)


    const cartItemsQuantityUpd = cartItems.reduce((total, { quantity }) => {
      return total + +quantity
    }, 0)

    setSubtotal(newSubtotal.toFixed(2))
    setCartItemsQuantity(cartItemsQuantityUpd)
    setIsLensesInCart(cartItems.some((el) => el.type === "contacts"))
  }, [cartItems])

  useEffect(() => {
    if (!order || !currentItem) return

    const newSubtotal =
      Object.values(order).reduce(
        (result, { price }) => (price ? (result += Number(price)) : result),
        0
      ) + (Number(currentItem.price) || 0)

    setOrderSubtotal(newSubtotal.toFixed(2))
  }, [order, currentItem])

  useEffect(() => {
    const currentCartItemValues = getCurrentCartItemValues()
    if (currentCartItemValues !== undefined) {
      setWizardFlow(currentCartItemValues.customization_step)
    }
  }, [currentCartItem])

  const addItemToCart = (
    frame,
    variant,
    frame_id,
    variant_id,
    cost,
    sku,
    lens_color,
    sunglasses,
    variantInfo
  ) => {
    const currentCartItems = [...cartItems]
    const cartItemNumber = Math.random().toString(36).substr(2, 5)
    currentCartItems.push({
      frame: frame,
      product_id: frame_id,
      variant_id: variant_id,
      variant: variant,
      item_number: cartItemNumber,
      cost: cost,
      sku: sku,
      customization_step: 1,
      lens_color: lens_color,
      sunglasses: sunglasses,
      variantInfo: variantInfo,
    })
    setCartItems(currentCartItems)
    localStorage.setItem("cart", JSON.stringify(currentCartItems))
    localStorage.setItem("currentFrame", frame)
    localStorage.setItem("currentVariant", variant)
    localStorage.setItem("currentCartItem", cartItemNumber)
    localStorage.setItem("currentVariantInfo", JSON.stringify(variantInfo))
    setWizardFlow(1)
    setCurrentFrame(frame)
    setCurrentVariant(variant)
    setCurrentCartItem(cartItemNumber)
  }

  const addCartItem = (
    lens_attribute,
    lens_attribute_value,
    lens_attribute_cost,
    lens_product_id,
    lens_variant_id,
    sku
  ) => {
    const result = cartItems.find(obj => {
      return obj.item_number === currentCartItem
    })
    result[lens_attribute] = lens_attribute_value
    result[`${lens_attribute}_cost`] = lens_attribute_cost
    result[`${lens_attribute}_product_id`] = lens_product_id
    result[`${lens_attribute}_variant_id`] = lens_variant_id
    result[`${lens_attribute}_sku`] = sku
    localStorage.setItem("cart", JSON.stringify(cartItems))
  }

  const setItemCustomizationStep = step => {
    const result = cartItems.find(obj => {
      return obj.item_number === currentCartItem
    })
    result["customization_step"] = step
    localStorage.setItem("cart", JSON.stringify(cartItems))
    setWizardFlow(step)
  }

  const getCurrentCartItemValues = () => {
    const result = cartItems.find(obj => {
      return obj.item_number === currentCartItem
    })
    setHasCurrentValues(true)
    return result
  }

  const updateItem = (newItem, itemId) => {
    const updatedCartItems = cartItems.map(item => {
      if (item?.cart_item_id === itemId) {
        return { ...newItem, cart_item_id: item?.cart_item_id, quantity: 1 }
      }
      return item
    })

    setCartItems(updatedCartItems)
    localStorage.setItem("cart", JSON.stringify(updatedCartItems))
  }

  const updateRx = (key, value, price, itemId) => {
    const updatedCartItems = cartItems.map(item => {
      const isOneEye = key === "prescription" ? [value.leftEye.power, value.rightEye.power]
        .some((power) => power === 'oneEye') : item?.isOneEye

      if (item.cart_item_id === itemId) {
        return { ...item, [key]: value, isOneEye, price }
      }
      return item
    })

    setCartItems(updatedCartItems)
    localStorage.setItem("cart", JSON.stringify(updatedCartItems))
  }

  const updatePrescription = (
    rightSph,
    rightCyl,
    rightAxis,
    leftSph,
    leftCyl,
    leftAxis,
    pd
  ) => {
    const result = cartItems.find(obj => {
      return obj.item_number === currentCartItem
    })
    result[`rightSph`] = rightSph
    result[`rightCyl`] = rightCyl
    result[`rightAxis`] = rightAxis
    result[`leftSph`] = leftSph
    result[`leftCyl`] = leftCyl
    result[`leftAxis`] = leftAxis
    result[`pd`] = pd
    localStorage.setItem("cart", JSON.stringify(cartItems))
  }

  const removeItem = cart_item => {
    let currentCartItems = [...cartItems]
    const result = cartItems.find(obj => {
      return obj.item_number === cart_item
    })
    currentCartItems = currentCartItems.filter(item => item !== result)
    setCartItems(currentCartItems)
    localStorage.setItem("cart", JSON.stringify(currentCartItems))
  }

  const clearCart = () => {
    setCartItems([])
    localStorage.setItem("cart", JSON.stringify([]))
    setDiscountCode("")
    localStorage.removeItem("discount_code")
  }

  const storeTryOnImage = image => {
    setCurrentTryOnImage(image)
    localStorage.setItem("tryOnImage", image)
  }

  const setNewCartItems = newCartItems => {
    setCartItems(newCartItems)
    localStorage.setItem("cart", JSON.stringify(newCartItems))
  }

  const addItemCart = (item, ccProducts) => {
    const newCartItems = ccProducts ? [] : [...cartItems]
    const cartItemId = Math.floor(Math.random() * 10000000000)

    newCartItems.push({ ...item, quantity: 1, cart_item_id: cartItemId })

    setNewCartItems(newCartItems)

    let item_category = item.type
    let item_category2 = item.type
    let item_category4 = 'N/A'
    let item_variant = 'N/A'

    // item category logic
    if (item_category === "glasses" || item_category === "sunglasses"){
      item_category = "frames"
    }
    if (item_category === "accessory"){
      item_category = "accessories"
    }
    /// item category 2 logic
    if (item_category2 === "glasses") {
      item_category2 = "optical"
    }
    if (item_category2 === "contacts") {
      item_category2 = "spherical"
    }

    if (item_category2 === "accessory"){
      item_category2 = "contact accessories"
    }


    if (item.title  === 'Hydro by Hubble Contact Lenses') {
      item_category4 = "Hioxifilcon A"
    }
    if (item.title === 'Hubble Classic Lenses') {
      item_category4 = 'Methafilcon A'
    }

    if (item.lens_quantity != null) {
      item_variant = `${item.lens_quantity} Pairs`
    }

    if (item.no_ga_fire !== true && typeof window !== 'undefined') {
      window.dataLayer.push({ ecommerce: null })
      window.dataLayer.push({
        event: "add_to_cart",
        ecommerce: {
          items: [
            {
              item_id: item.id,
              item_name: item.title,
              currency: 'USD',
              discount: 0,
              index: 0,
              item_brand: 'Hubble',
              item_category: item_category,
              item_category2: item_category2,
              item_category3: item.type === 'contacts' ? `${item?.secondSaveFlow ? `${item?.cadence} Months Supply` : `Every ${item?.cadence} Weeks`}` : 'N/A',
              item_category4: item_category4,
              item_category5: 'N/A',
              item_list_id:   item.type === 'contacts' ? 'Contacts' : 'N/A',
              item_list_name: `N/A`,
              item_variant:   item_variant,
              price: parseFloat(item.price),
              index: 1
            }
          ]
        }
      })
    }
  }



  const removeItemCart = (itemId) => {
    const item = cartItems.find(obj => {
      return obj.cart_item_id === itemId
    })

    const newCartItems = cartItems.filter(({ cart_item_id }) => cart_item_id !== itemId)

    setNewCartItems(newCartItems)



    let item_category = item.type
    let item_category2 = item.type

    // item category logic
    if (item_category === "glasses" || item_category === "sunglasses"){
      item_category = "frames"
    }
    if (item_category === "accessory"){
      item_category = "accessories"
    }
    /// item category 2 logic
    if (item_category2 === "glasses") {
      item_category2 = "optical"
    }
    if (item_category2 === "contacts") {
      item_category2 = "spherical"
    }

    if (item_category2 === "accessory"){
      item_category2 = "contact accessories"
    }



    if (typeof window !== 'undefined') {
      window.dataLayer.push({ ecommerce: null })
      window.dataLayer.push({
        event: "remove_from_cart",
        ecommerce: {
          items: [
            {
              item_id: `${itemId}`,
              item_name: item.title,
              currency: 'USD',
              discount: 0,
              index: 0,
              item_brand: 'Hubble',
              item_category: item_category,
              item_category2: item_category2,
              item_category3: item.productGender,
              item_category4: 'N/A',
              item_category5: 'N/A',
              item_list_id:   `${item.productGender}-${item.type}`,
              item_list_name: `${item.productGender}-${item.type}`,
              item_variant:   `${item.title}-${item.colorTitle}`,
              price: parseFloat(item.price),
            }
          ]
        }
      })
    }

    clearOrder()
  }

  const setQuantity = (itemId, quantity) => {
    const newCartItems = cartItems.map(item =>
      item.cart_item_id === itemId ? { ...item, quantity } : item
    )

    setNewCartItems(newCartItems)
  }

  const createOrder = (item, order) => {
    setCurrentItem(item)
    setOrder(order)
  }

  const clearOrder = () => {
    setCurrentItem(null)
    setOrder(null)
  }

  const removeOrder = () => setOrder(null)

  return (
    <CartContext.Provider
      value={{
        isCartInitialized,
        cartItems,
        cartItemsQuantity,
        wizardFlow,
        currentFrame,
        currentVariant,
        currentVariantInfo,
        currentCartItem,
        prescriptionType,
        lensType,
        cartDisplay,
        hasCurrentValues,
        headerColor,
        subtotal,
        order,
        orderSubtotal,
        isOrderError,
        currentItem,
        discountPercentage,
        discountCode,
        isHydro,
        isSkyhy,
        isAvg,
        isLensesInCart,
        taxes,
        setTaxes,
        setLensType,
        setPrescriptionType,
        setCurrentVariant,
        setCurrentVariantInfo,
        setWizardFlow,
        setCartItems,
        setCartDisplay,
        setCurrentFrame,
        addItemToCart,
        removeItem,
        addCartItem,
        updatePrescription,
        updateRx,
        updateItem,
        getCurrentCartItemValues,
        setItemCustomizationStep,
        setCurrentCartItem,
        clearCart,
        setCurrentTryOnImage,
        currentTryOnImage,
        storeTryOnImage,
        setHeaderColor,
        addItemCart,
        removeItemCart,
        setQuantity,
        createOrder,
        setOrder,
        clearOrder,
        setIsOrderError,
        removeOrder,
        setCurrentItem,
        clearCart,
        setDiscountPercentage,
        setDiscountCode,
        setIsHydro,
        setIsSkyhy,
        setIsAvg,
        setIsLensesInCart,
      }}
    >
      {props.children}
    </CartContext.Provider>
  )
}

export default CartContext
export { CartContextProvider }
