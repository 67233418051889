import React, { createContext, useState, useEffect } from 'react';

const DefaultState = {
  customer: {
    firstName: '',
    lastName: '',
  },
  doctor: {
    firstName: '',
    lastName: '',
    id: '',
  },
  prescription: {
    leftEye: {
      power: '',
      baseCurve: '',
      axis: '',
      addPower: '',
      cylinder: '',
    },
    rightEye: {
      power: '',
      baseCurve: '',
      axis: '',
      addPower: '',
      cylinder: '',
    },
  },
  brand: {
    title: '',
    price: '',
    cc_product_id: '',
    sale_price: '',
    value: '',
    days: '',
    percent_off: ''
  },
  shippingAddress: {
    first_name: "",
    last_name: "",
    address1: "",
    address2: "",
    city: "",
    province: "",
    zipcode: "",
    phone: "",
  },
  taxes: 0,
  brandSelected: false,
  submitButtonText: "",
  requiredError: false,
  discount: {
    code: null,
    percentOff: null,
  },
  setCustomer: () => {},
  setDoctor: () => {},
  setPrescription: () => {},
  setBrand: () => {},
  setSubmitButtonText: () => {},
  setBrandSelected: () => {},
  accessories: [],
  setAccessories: () => {},
  setRequiredError: () => {},
}

const HubbleOrderContext = createContext(DefaultState);

const HubbleOrderContextProvider = ({ children }) => {
  const [customer, setCustomer] = useState({
    firstName: '',
    lastName: '',
  });
  const [doctor, setDoctor] = useState({
    firstName: '',
    lastName: '',
    id: "",
  });
  const [shippingAddress, setShippingAddress] = useState({
    first_name: "",
    last_name: "",
    address1: "",
    address2: "",
    city: "",
    province: "",
    zipcode: "",
    phone: "",
  })
  const [prescription, setPrescription] = useState({
    leftEye: {
      power: '',
      base_curve: '',
      axis: '',
      add_power: '',
      cylinder: '',
    },
    rightEye: {
      power: '',
      base_curve: '',
      axis: '',
      add_power: '',
      cylinder: '',
    },
  });
  const [brand, setBrand] = useState({
    title: '',
    price: '',
    cc_product_id: '',
    sale_price: '',
    value: '',
    days: '',
    percent_off: ''
  });
  const [discount, setDiscount] = useState({
    code: null,
    percentOff: null,
  });
  const [taxes, setTaxes] = useState(0)
  const [brandSelected, setBrandSelected] = useState(false)
  const [submitButtonText, setSubmitButtonText] = useState('Complete Order')
  const [accessories, setAccessories] = useState([])
  const [requiredError, setRequiredError] = useState(false)

  const clearShippingAddress = () => {
    setShippingAddress({
      first_name: "",
      last_name: "",
      address1: "",
      address2: "",
      city: "",
      province: "",
      zipcode: "",
      phone: "",
    })
  }

  return (
    <HubbleOrderContext.Provider
      value={{
        customer,
        doctor,
        prescription,
        brand,
        taxes,
        shippingAddress,
        brandSelected,
        setCustomer,
        setDoctor,
        setPrescription,
        setBrand,
        setTaxes,
        setShippingAddress,
        submitButtonText,
        requiredError,
        discount,
        setDiscount,
        setSubmitButtonText,
        setBrandSelected,
        clearShippingAddress,
        accessories,
        setAccessories,
        setRequiredError,
      }}
    >
      {children}
    </HubbleOrderContext.Provider>
  );
};

export default HubbleOrderContext;
export { HubbleOrderContextProvider };
